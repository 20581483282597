import React, { useState } from 'react';
import './tabs.scss';
import PropTypes from 'prop-types';
import clock from '../../assets/images/icons/clock.svg';
import clockselected from '../../assets/images/icons/clock-selected.svg';
import cri from '../../assets/images/icons/cri.svg';
import criselected from '../../assets/images/icons/cri-selected.svg';
import spint from '../../assets/images/icons/spint.svg';
import spintselected from '../../assets/images/icons/spint-selected.svg';
import cre from '../../assets/images/icons/cre.svg';
import creselected from '../../assets/images/icons/cre-selected.svg';
import general from '../../assets/images/icons/general.svg';
import generalselected from '../../assets/images/icons/general-selected.svg';

const Tabs = ({ tabs, onChange }) => {
  const [selected, setSelected] = useState(0);

  const tabIcon = (tab, selected) => {
    let selectedIcon = '';
    switch (tab) {
      case 'HORARIOS': {
        selectedIcon = selected ? clockselected : clock;
        break;
      }
      case 'CRI': {
        selectedIcon = selected ? criselected : cri;
        break;
      }
      case '26KM KOM': {
        selectedIcon = selected ? spintselected : spint;
        break;
      }
      case 'CRE': {
        selectedIcon = selected ? creselected : cre;
        break;
      }
      case 'GENERAL': {
        selectedIcon = selected ? generalselected : general;
        break;
      }
      default: {
        selectedIcon = selected ? criselected : cri;
        break;
      }
    }
    return selectedIcon;
  };

  return (
    <div className='tabs'>
      {tabs.map((tab, index) => {
        return (
          <div
            key={tab}
            className={`tab${index === selected ? ' selected' : ''}`}
            onClick={() => {
              setSelected(index);
              onChange(index);
            }}
          >
            <img src={tabIcon(tab, index === selected)} alt={tab}></img>{' '}
            <div>{tab}</div>
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;
