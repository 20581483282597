import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Loading from '../../components/loading';
import './home.scss';
import Header from '../Header';
import Nav from '../Nav';
import Tabs from '../Tabs';
import TableRankingTitle from '../TableRankingTitle';
import TableRankingIndividual from '../TableRankingIndividual';
import TableRankingTeam from '../TableRankingTeam';
import TableCalendarTeam from '../TableCalendarTeam';
import TableCalendarIndividual from '../TableCalendarIndividual';
import FilterModal from '../FilterModal';
import config from '../../config';
import handleResponse from '../../utils/handleResponse';
import banner from '../../assets/images/BannersTTLaRevancha_3.png';
import bannerMobile from '../../assets/images/BannersTTLaRevancha_2.png';

const TABS = [
  'HORARIOS',
  '9KM',
  '20KM CARTAGO',
  '26KM KOM',
  'CRI',
  'CRE',
  'GENERAL',
];
const SUM_OF_TIMES = 'sum_of_times';
const THIRD_TO_ARRIVE = 'third_to_arrive';

const CALENDAR_INDIVIDUAL = 0;
const CALENDAR_TEAM = 1;
const RANKING_INDIVIDUAL = 2;
const RANKING_TEAM = 3;
const RANKING_GENERAL = 4;
const RANKING_SPRINT = 5;

const TAB_CLOCK = 0;
const TAB_KM9 = 1;
const TAB_KM20 = 2;
const TAB_KM28 = 3;
const TAB_INDIVIDUAL = 4;
const TAB_TEAM = 5;
const TAB_GENERAL = 6;

const Home = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSecondLoading, setShowSecondLoading] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [individualSegment, setIndividualSegment] = useState(false);
  const [showIndividualFavoriteLoading, setShowIndividualFavoriteLoading] =
    useState(false);
  const [showTeamFavoriteLoading, setShowTeamFavoriteLoading] = useState(false);
  const [raceEvents, setRaceEvents] = useState([]);
  const [individualFavorites, setIndividualFavorites] = useState([]);
  const [teamFavorites, setTeamFavorites] = useState([]);
  const [raceEvent, setRaceEvent] = useState(null);
  const [raceDay, setRaceDay] = useState(null);
  const [lastRaceDay, setLastRaceDay] = useState(null);
  const [selectedCityFilter, setSelectedCityFilter] = useState(null);
  const [selectedSegmentFilter, setSelectedSegmentFilter] = useState(null);
  const [selectedGenderFilter, setSelectedGenderFilter] = useState(null);
  const [selectedGenderCategoryFilter, setSelectedGenderCategoryFilter] =
    useState(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);
  const [
    selectedIndividualCategoryFilter,
    setSelectedIndividualCategoryFilter,
  ] = useState(null);
  const [selectedAgeCategoryFilter, setSelectedAgeCategoryFilter] =
    useState(null);
  const [selectedSportFilter, setSelectedSportFilter] = useState(null);
  const [changeIndividual, setChangeIndividual] = useState(null);
  const [changeTeam, setChangeTeam] = useState(null);
  const resetFilters = () => {
    setSelectedCityFilter(null);
    setSelectedSegmentFilter(null);
    setSelectedGenderFilter(null);
    setSelectedGenderCategoryFilter(null);
    setSelectedAgeCategoryFilter(null);
    setSelectedCategoryFilter(null);
    setSelectedIndividualCategoryFilter(null);
    setSelectedSportFilter(null);
    if (isIndividual) {
      setChangeIndividual(new Date().getTime());
      setChangeTeam(null);
    } else {
      setChangeTeam(new Date().getTime());
      setChangeIndividual(null);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (raceEvents.length > 0) {
      setRaceEvent(raceEvents[0]);
    }
  }, [raceEvents]);

  useEffect(() => {
    if (raceEvent) {
      getIndividualFavorites();
      getTeamFavorites();

      // setRaceDay(raceEvent.raceDays[1]);
      setRaceDay(raceEvent.selectedRaceDay);
      if (raceEvent.raceDays.length > 0) {
        // const now = moment();
        // let newRaceDay;
        // raceEvent.raceDays.forEach((filterRaceDay) => {
        //   const start = moment(filterRaceDay.startDate);
        //   const end = moment(filterRaceDay.endDate);
        //   if (now > start && now < end) {
        //     newRaceDay = filterRaceDay;
        //   }
        // });
        // if (!newRaceDay) {
        //   const start = moment(raceEvent.startDate);
        //   const end = moment(raceEvent.endDate);
        //   if (now < start) {
        //     newRaceDay = raceEvent.raceDays[0];
        //   }
        //   if (now > end) {
        //     newRaceDay = raceEvent.raceDays[raceEvent.raceDays.length - 1];
        //   }
        // }
        // newRaceDay = raceEvent.raceDays[1];
        // setRaceDay(newRaceDay);
        if (raceEvent.raceDays[1]) {
          setLastRaceDay(raceEvent.raceDays[1]);
        }
      }
    }
  }, [raceEvent]);

  const getEvents = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(
      `${config.apiUrl}/api/race-events?pagination[page]=1`,
      requestOptions
    );
    setShowLoading(false);
    try {
      const events = await handleResponse(response);
      setRaceEvents(events.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getIndividualFavorites = async () => {
    // setShowIndividualFavoriteLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/individual-ranking-favorites`
      );
    }

    const response = await fetch(url, requestOptions);
    // setShowIndividualFavoriteLoading(false);
    try {
      const newIndividualFavorites = await handleResponse(response);
      setIndividualFavorites(newIndividualFavorites);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getTeamFavorites = async () => {
    // setShowTeamFavoriteLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/general-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/general-ranking-favorites`
      );
    }

    const response = await fetch(url, requestOptions);

    // setShowTeamFavoriteLoading(false);
    try {
      const newTeamFavorites = await handleResponse(response);
      setTeamFavorites(newTeamFavorites);
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        favorite,
      }),
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/individual-ranking-favorites`
      );
    }

    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveTeamFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        favorite,
      }),
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/general-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/${raceEvent._id}/general-ranking-favorites`
      );
    }

    await fetch(url, requestOptions);

    // setShowLoading(false);
    try {
      getTeamFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'DELETE',
      headers,
    };
    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/individual-ranking-favorites/${favorite}`
    );
    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteTeamFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'DELETE',
      headers,
    };
    const url = new URL(
      `${config.apiUrl}/api/race-events/${raceEvent._id}/general-ranking-favorites/${favorite}`
    );
    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getTeamFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const [rakingType, setRakingType] = useState(0);
  const onTabChange = (tab) => {
    console.log('tab', tab);
    let newRakingType = 0;
    switch (tab) {
      case TAB_CLOCK: {
        setIndividualSegment(null);
        if (raceDay.raceType === SUM_OF_TIMES) {
          setIsIndividual(true);
          newRakingType = CALENDAR_INDIVIDUAL;
        } else {
          setIsIndividual(false);
          newRakingType = CALENDAR_TEAM;
        }
        break;
      }
      case TAB_KM9: {
        setIsIndividual(true);
        setIndividualSegment('elapsedTime9km');
        newRakingType = RANKING_INDIVIDUAL;
        break;
      }
      case TAB_KM20: {
        setIndividualSegment('elapsedTime20km');
        setIsIndividual(true);
        newRakingType = RANKING_INDIVIDUAL;
        break;
      }
      case TAB_KM28: {
        setIndividualSegment('elapsedTime28km');
        setIsIndividual(true);
        newRakingType = RANKING_INDIVIDUAL;
        break;
      }
      case TAB_INDIVIDUAL: {
        setIndividualSegment(null);
        setIsIndividual(true);
        newRakingType = RANKING_INDIVIDUAL;
        break;
      }
      case TAB_TEAM: {
        setIndividualSegment(null);
        setIsIndividual(false);
        newRakingType = RANKING_TEAM;
        break;
      }
      case TAB_GENERAL: {
        setIndividualSegment(null);
        setIsIndividual(false);
        newRakingType = RANKING_GENERAL;
        break;
      }
      default: {
        setIndividualSegment(null);
        if (raceDay.raceType === SUM_OF_TIMES) {
          setIsIndividual(true);
          newRakingType = CALENDAR_INDIVIDUAL;
        } else {
          setIsIndividual(false);
          newRakingType = CALENDAR_TEAM;
        }
        break;
      }
    }
    setRakingType(newRakingType);
  };

  useEffect(() => {
    if (raceDay) {
      onTabChange(0);
    }
  }, [raceDay]);

  const renderTable = () => {
    switch (rakingType) {
      case CALENDAR_INDIVIDUAL:
        return (
          <TableCalendarIndividual
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            individualFavorites={individualFavorites}
            saveFavorite={saveFavorite}
            deleteFavorite={deleteFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedSegmentFilter={selectedSegmentFilter}
            selectedGenderFilter={selectedGenderFilter}
            selectedIndividualCategoryFilter={selectedIndividualCategoryFilter}
            selectedSportFilter={selectedSportFilter}
            changeIndividual={changeIndividual}
            selectedAgeCategoryFilter={selectedAgeCategoryFilter}
          />
        );
      case CALENDAR_TEAM:
        return (
          <TableCalendarTeam
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            teamFavorites={teamFavorites}
            saveTeamFavorite={saveTeamFavorite}
            deleteTeamFavorite={deleteTeamFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedGenderCategoryFilter={selectedGenderCategoryFilter}
            selectedCategoryFilter={selectedCategoryFilter}
            changeTeam={changeTeam}
          />
        );
      case RANKING_INDIVIDUAL:
        return (
          <TableRankingIndividual
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            individualFavorites={individualFavorites}
            saveFavorite={saveFavorite}
            deleteFavorite={deleteFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedSegmentFilter={selectedSegmentFilter}
            selectedGenderFilter={selectedGenderFilter}
            selectedIndividualCategoryFilter={selectedIndividualCategoryFilter}
            selectedSportFilter={selectedSportFilter}
            changeIndividual={changeIndividual}
            selectedAgeCategoryFilter={selectedAgeCategoryFilter}
            individualSegment={individualSegment}
          />
        );
      case RANKING_TEAM:
        return (
          <TableRankingTeam
            cre={true}
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            teamFavorites={teamFavorites}
            saveTeamFavorite={saveTeamFavorite}
            deleteTeamFavorite={deleteTeamFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedGenderCategoryFilter={selectedGenderCategoryFilter}
            selectedCategoryFilter={selectedCategoryFilter}
            changeTeam={changeTeam}
          />
        );
      case RANKING_SPRINT:
        return (
          <TableRankingTeam
            sprint={true}
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            teamFavorites={teamFavorites}
            saveTeamFavorite={saveTeamFavorite}
            deleteTeamFavorite={deleteTeamFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedGenderCategoryFilter={selectedGenderCategoryFilter}
            selectedCategoryFilter={selectedCategoryFilter}
            changeTeam={changeTeam}
          />
        );
      case RANKING_GENERAL:
        return (
          <TableRankingTeam
            raceDay={raceDay}
            raceEvent={raceEvent}
            setShowLoading={setShowLoading}
            setShowSecondLoading={setShowSecondLoading}
            teamFavorites={teamFavorites}
            saveTeamFavorite={saveTeamFavorite}
            deleteTeamFavorite={deleteTeamFavorite}
            selectedCityFilter={selectedCityFilter}
            selectedGenderCategoryFilter={selectedGenderCategoryFilter}
            selectedCategoryFilter={selectedCategoryFilter}
            changeTeam={changeTeam}
          />
        );
      default:
        <TableCalendarTeam
          raceDay={raceDay}
          raceEvent={raceEvent}
          setShowLoading={setShowLoading}
          setShowSecondLoading={setShowSecondLoading}
          saveTeamFavorite={saveTeamFavorite}
          deleteTeamFavorite={deleteTeamFavorite}
          teamFavorites={teamFavorites}
          selectedCityFilter={selectedCityFilter}
          selectedGenderCategoryFilter={selectedGenderCategoryFilter}
          selectedCategoryFilter={selectedCategoryFilter}
          changeTeam={changeTeam}
        />;
        break;
    }
  };

  const goRappi = () => {
    window.open('https://bit.ly/3A6WCbC', '_blank').focus();
  };

  return (
    <div className='home'>
      {(showLoading ||
        showIndividualFavoriteLoading ||
        showTeamFavoriteLoading ||
        showSecondLoading) && <Loading />}
      {showFilterModal && (
        <FilterModal
          setShowLoading={setShowLoading}
          isIndividual={isIndividual}
          isIndividualCalendar={rakingType === CALENDAR_INDIVIDUAL}
          closeModal={() => {
            setShowFilterModal(false);
          }}
          selectedCityFilter={selectedCityFilter}
          selectedSegmentFilter={selectedSegmentFilter}
          selectedGenderFilter={selectedGenderFilter}
          selectedGenderCategoryFilter={selectedGenderCategoryFilter}
          selectedCategoryFilter={selectedCategoryFilter}
          selectedIndividualCategoryFilter={selectedIndividualCategoryFilter}
          selectedAgeCategoryFilter={selectedAgeCategoryFilter}
          selectedSportFilter={selectedSportFilter}
          setSelectedCityFilter={setSelectedCityFilter}
          setSelectedSegmentFilter={setSelectedSegmentFilter}
          setSelectedGenderFilter={setSelectedGenderFilter}
          setSelectedGenderCategoryFilter={setSelectedGenderCategoryFilter}
          setSelectedCategoryFilter={setSelectedCategoryFilter}
          setSelectedIndividualCategoryFilter={
            setSelectedIndividualCategoryFilter
          }
          setSelectedAgeCategoryFilter={setSelectedAgeCategoryFilter}
          setSelectedSportFilter={setSelectedSportFilter}
          setChangeIndividual={setChangeIndividual}
          setChangeTeam={setChangeTeam}
        />
      )}
      <div className='fixed-header'>
        <Header
          resetFilters={resetFilters}
          showReset={
            !!selectedCityFilter ||
            !!selectedAgeCategoryFilter ||
            !!selectedGenderFilter ||
            !!selectedSegmentFilter ||
            !!selectedGenderCategoryFilter ||
            !!selectedCategoryFilter ||
            !!selectedIndividualCategoryFilter ||
            !!selectedSportFilter
          }
        />
        <div className='banner hide-mobile'>
          <img src={banner} alt='adds' />
        </div>
        <div className='banner hide-desktop'>
          <img src={bannerMobile} alt='adds' />
        </div>
        <Nav
          openFilter={() => {
            setShowFilterModal(true);
          }}
        />
        <Tabs tabs={TABS} tab={3} onChange={onTabChange} />
        <TableRankingTitle rakingType={rakingType} />
      </div>
      {raceEvent && raceDay && renderTable()}
    </div>
  );
};

export default Home;
